<template>
  <div class="events">
    <PozzyEvents/>
  </div>
</template>

<script>
// @ is an alias to /src
import PozzyEvents from '@/components/Events.vue'

export default {
  name: 'Events',
  components: {
    PozzyEvents
  }
}
</script>
