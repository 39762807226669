<template>
  <v-container>
    <v-row class="text-center">
      <v-col
          class="mb-5"
          cols="auto"
      >
        <v-col class="mb-4">
          <h1 class="display-2 font-weight-bold mb-3">
            Pozzyvibes Current Events
          </h1>
        </v-col>
        <v-divider></v-divider>
        <v-container fluid>
          <v-row dense>
            <v-col
                v-for="event in events"
                :key="event.title"
                :cols="auto"
            >
              <v-card
                  :loading="loading"
                  class="mx-auto my-12"
                  
              >
                <v-img
                    :src="event.src"
                    class="white--text align-end"
                >
                </v-img>

                <v-card-text>
                  <div v-html="event.title"> </div>
                  <div v-html="event.description"> </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-card
                  class="mx-auto my-12"
                  height="800px"
              >
                <v-card-text>
                  <embed
                      src="https://calendar.google.com/calendar/embed?src=7kab5hnaeigvdp10ct4056tudk%40group.calendar.google.com&ctz=America%2FNew_York"
                      width="100%"
                      height="740px"
                  >
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PozzyEvents',
  data: () => ({
    events: [
     {
        title: '<p><h3>Pozzyvibes Back to School Sale!</p>',
        description: '<p>Now through October 1, 2024<br>Book your 2024 or 2025 event before Oct. 1st & save<br>50% Off 360 Photo Booth<br>More info at: <a href="https://pozzyvibes360.my.canva.site/back-to-school-sale" target="_blank">Pozzyvibes Back to School Sale!</a></p>',
        src: require("../assets/events/Pozzyvibes-360-Photo-Booth-Back-to-School-Sale-2024.png")
      },
      {
        title: '<p><h3>5th Annual Festival of Fall!</p>',
        description: '<p>Saturday, September 28, 2024<br>10am - 3pm<br>City Center, Port Orange, FL<br>More info at: <a href="https://www.facebook.com/events/399497159484461/" target="_blank">facebook.com</a></p>',
        src: require("../assets/events/5th-Annual-Festival-of-Fall-2024-Pozzyvibes.png")
      }],

  }),
}
</script>

<style scoped>
eb1 {
  text-align: center;
}
</style>